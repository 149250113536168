import React from "react";
import AboutGoalsContainer from "./AboutGoalsContainer";
import Navbar from "../../../components/Navbar";
import { Box } from "@mui/material";
import AboutBottomIcons from "./AboutBottomIcons";
import BottomIcons from "../../Landing_Page/components/BottomIcons";
import new_hero_bg from "../../../assets/new_hero_bg.png";
// import helmet from "helmet";



const AboutHero = () => {
  return (
    <Box
      sx={{
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        height: { xs: "auto", md: "100vh" },
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    >
      
      <Navbar currentPage={"About"} />
      <Box
        display="block"
        sx={{
          position: { md: "absolute" },
          top: { md: "50%" },
          left: { md: "50%" },
          transform: {
            md: "translate(-50%, -59%)",
          },
          textAlign: "left",
          width: "100%",
          marginLeft: { xs: "0", md: "5%" },
        }}
        zIndex={3}
      >
        <AboutGoalsContainer />

      </Box>

      {/* <Box
        display={{ xs: "block", md: "none" }}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -50%)",
            md: "translate(-50%, -59%)",
          },
          textAlign: "left",
          width: "100%",
          marginLeft: { xs: "0", md: "5%" },
        }}
        zIndex={3}
      >
        <GoalsContainers
          icon={AboutPageIcon}
          title={"About Us"}
          subtext={
            "Unite industry and researchers with speed and precision, catalyzing innovation. We're here to make R&D collaboration happen swiftly."
          }
          points={AboutPoints}
          reverseOrder={true}
        />
      </Box> */}
      <Box
        display={{ xs: "none", md: "block" }}
      >
        <BottomIcons/>
      </Box>
      <Box
        display={{ xs: "block", md: "none" }}
      >
        <AboutBottomIcons/>
      </Box>

    </Box>
  );
};

export default AboutHero;
