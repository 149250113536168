import React from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AddIcon from "@mui/icons-material/Add";

const SearchTextField = () => {
  return (
    <TextField
      size="small"
      variant="outlined"
      style={{ backgroundColor: "black", borderRadius: "50px", width: "auto" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "white" }} />
          </InputAdornment>
        ),
        style: { color: "white", borderRadius: "50px" },
      }}
      placeholder="Search"
    />
  );
};

const AltNav = ({ btn_text }) => {
  return (
    <Box
      sx={{
        background: "transparent",
        display: { xs: "none", md: "flex" },
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: "80px",
        padding: "5px 0px 0px 0px",
      }}
    >
      <SearchTextField />
      <Box display="flex" alignItems="center" justifyContent="center">
        <NotificationsActiveOutlinedIcon
          style={{ color: "white", marginRight: "20px" }}
        />
        <Button
          sx={{
            background: "#3FA268",
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "28.08px",
            letterSpacing: "-0.832px",
            borderRadius: "50px",
            textAlign: "center",
            padding: "2px 15px",
            display: "flex",
            textDecoration: "none",
            alignItems: "center", // Center text and icon vertically
            "&:hover": {
              background: "#3FA268",
            },
          }}
          onClick={() => {
            window.open(
              "https://calendly.com/team-reslink/meeting",
              "_blank"
            );
          }}
        >
          <IconButton
            color="inherit"
            sx={{
              textDecoration: "none",
              margin: "0",
              padding: "5px 0px",
            }}
          >
            <AddIcon /> {/* The left arrow icon */}
          </IconButton>
          {btn_text}
        </Button>
      </Box>
    </Box>
  );
};

export default AltNav;
