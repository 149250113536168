import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Divider,
  IconButton,
} from "@mui/material";
import Company from "../../../assets/icon_images/Company.png";
import EastIcon from "@mui/icons-material/East";
import ResearchMarketing from "../../../assets/icon_images/ResearchMarketing.png";
import globe from "../../../assets/icon_images/globe.png";
import restart from "../../../assets/icon_images/restart.png";
import increase from "../../../assets/icon_images/increase.png";
import charts from "../../../assets/charts.png";
import people_group from "../../../assets/people_group.png";
import CardWithBigButton from "./CardWithBigButton";
import { GoalsContainers } from "./GoalsContainers";
import { FeaturedServicesCards } from "./FeaturedServicesCards";
import RatingCarousel from "./RatingCarousel";
import { Link } from "react-router-dom";

const GoalPoints = [
  "We help you collaborate with right researcher with minimum friction.",
  "Streamline all your R&D processes & communications-All at one place.",
  "Milestone based R&D with stagewise deployment of capital for better ROI.",
];

const GoalPoints2 = [
  "Remove R&D complexities with clear milestones and a defined approach.",
  "Empowering innovation through transparent progress tracking.",
];

const ForIndustryAndResearch = ({ img, title, subtext }) => {
  return (
    <Box
      display="flex"
      width="100%"
      // height="50%"
      padding={{ xs: "20px 10px", md: "40px 20px" }}
      justifyContent="center"
      alignItems="center"
      gap={6}
    >
      <img
        src={img}
        alt="industry_and_research_icon"
        width={{ xs: "30px", md: "55px" }}
        height={{ xs: "30px", md: "55px" }}
        style={{ flexShrink: 0 }}
      />
      <Box textAlign="left" width="63%" marginLeft="-10px">
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Bold",
            fontSize: { xs: "1.125rem", md: "23px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: " 1px",
            marginBottom: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Regular",
            fontSize: { xs: "0.75rem", md: "16px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "26.08px" /* 143.265% */,
            letterSpacing: " 1px",

            marginBottom: "10px",
          }}
        >
          {subtext}
        </Typography>
        <IconButton
          onClick={() => {
            window.open(
              "https://calendly.com/team-reslink/meeting",
              "_blank"
            );
          }}
          sx={{
            display: "flex",
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            gap: 1,
            alignItems: "center",
            color: "#FEFEFE",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Medium",
              fontSize: { xs: "0.75rem", md: "16px" },

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "26.08px" /* 143.265% */,
              letterSpacing: " 1px",

            }}
          >
            Learn More
          </Typography>
          <EastIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

const FeaturedServices = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "auto",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "80px 0px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          textAlign: "center",
          width: "auto",
          overflowX: "hidden",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid
          container
          spacing={{ xs: 0, md: "auto" }}
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Grid item md={6}>
            <Box
              textAlign="center"
              width={{ xs: "100%", md: "80%" }}
              marginLeft={{ xs: "8%", md: "20%" }}
              marginRight={{ xs: "auto", md: "10%" }}
            >
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Gilroy-Bold",
                  fontSize: { xs: "2rem", md: "50px" },
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal" /* 129.948% */,
                  letterSpacing: " 1px",

                  textAlign: "left",
                }}
              >
                From Concept to Completion: Holistic{" "}
                <span style={{ color: "#3FA268" }}>
                  <br />
                  R&D Solutions
                </span>
              </Typography>
              <Typography
                sx={{
                  width: "80%",
                  textAlign: "left",
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Gilroy-Regular",
                  fontSize: { xs: "1rem", md: " 18px" },
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "26.08px" /* 143.265% */,
                  letterSpacing: " 1px",

                  marginTop: "30px",
                }}
              >
                At Reslink, we excel in providing holistic R&D solutions that
                encompass every stage of your project.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Card
              sx={{
                // marginLeft: "-7%",
                height: "100%",
                borderRadius: "10px",
                border: "1px solid rgba(255, 255, 255, 0.05)",
                background:
                  "radial-gradient(70% 70% at 90% 90%, rgba(0, 255, 101, 0.06) 0%, rgba(255, 255, 255, 0.04) 100%)",
                backdropFilter: "blur(3.5999999046325684px)",
                maxWidth: { xs: "93%", md: "80%" },
                marginLeft: { xs: "auto", md: "auto" },
                marginRight: { xs: "auto", md: "18%" },
                marginTop: { xs: "53px", md: "0px" },
              }}
            >
              <ForIndustryAndResearch
                img={Company}
                title="For Industry and Businessess"
                subtext="Your business needs a reliable team of research specialists."
              />
              <Divider
                sx={{
                  background: "rgba(255, 255, 255, 0.05)",
                  borderBottomWidth: 2,
                }}
              />
              <ForIndustryAndResearch
                img={ResearchMarketing}
                title="For Researchers"
                subtext="Your experience is valuable to us. Join Reslink for impactful collaborations."
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box height={{ xs: "100px", md: "142px" }}></Box>
      <Box
        width="100%"
        textAlign="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Card
          sx={{
            borderRadius: "20px",
            border: "1px solid rgba(255, 255, 255, 0.05)",
            background:
              "rgba(255, 255, 255, 0.04) ",
            backdropFilter: "blur(3.5999999046325684px)",
            maxWidth: { xs: "93%", md: "90%" },
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box
            textAlign="center"
            width="100%"
            padding={{ xs: "50px 0px", md: "auto" }}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Bold",
                fontSize: { xs: "1.875rem", md: "50px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "75px" /* 129.948% */,
                letterSpacing: " 1px",

                textAlign: "center",
                marginBottom: { xs: "10px", md: "20px" },
              }}
            >
              Featured Services
            </Typography>
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: { xs: "0.875rem", md: " 18px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: { xs: "normal", md: "28.08px" } /* 143.265% */,
                letterSpacing: " 1px",
                padding: { xs: "0px 20px", md: "none" },
              }}
            >
              Expand your business at scale with Circle infrastructure that
              unlocks the power of digital dollars.
            </Typography>
            <Box height={{ xs: "10px", md: "20px" }}></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                marginTop: "50px",
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <FeaturedServicesCards
                icon={globe}
                title="R&D Simplified"
                subtext="We provide everything you need to succeed in your R&D projects, from finding the best researchers and IPs to refining your approach and executing your plan."
              />
              <FeaturedServicesCards
                icon={restart}
                title="Resource Utilization"
                subtext="Reslink helps you to pull together the right resources for your project, including chemicals, labs, researchers, and equipments, allowing you to utilize your resources efficiently."
              />
              <FeaturedServicesCards
                icon={increase}
                title="Collaboration and Innovation"
                subtext="We make it easy for researchers and innovators to collaborate with minimum friction, which helps you to achieve your goals and get a better ROI."
              />
            </Box>
          </Box>
        </Card>
      </Box>

      <Box height="130px"></Box>
      <GoalsContainers
        icon={people_group}
        title="Connecting Visionaries with Opportunities"
        subtext="We help you efficiently collaborate with researchers with skills tailored to your specific requirements, minimizing friction and getting you started with work in 15 days with the help of our deep learning models."
        points={GoalPoints}
      />
      <Box height="85px"></Box>
      <GoalsContainers
        icon={charts}
        title="Tracking&nbsp;Progress: Turning Vision into Milestones"
        subtext="We've embraced the power of milestones in R&D. Each project is broken down into achievable steps, ensuring clarity and progress at every stage. With objective milestones, you'll always know where you stand on the path to innovation."
        points={GoalPoints2}
        reverseOrder={true}
      />

      <Box height={{ xs: "80px", md: "130px" }}></Box>

      {/* <RatingCarousel/> */}
      {/* <Box height="96px"></Box> */}

      <CardWithBigButton
        title={"Accelerate your R&D with Reslink. Join us today!"}
        subtext={
          "Bridging the Gap Between Researchers and Industries for a Better Tomorrow. "
        }
      />
    </Box>
  );
};

export default FeaturedServices;
