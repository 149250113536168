import React from "react";
import { Box, Typography, Button, Card } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import "./PriceCardDiv.css"

const CardWithBigButtonPricing = () => {
  return (
    <div
     className="custom-button-card-div"
    >
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          padding: { xs: "40px 18px", md: "80px 10px" },
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box
          // textAlign={{ xs: "left", md: "center" }}
          width={{ xs: "100%", md: "54%" }}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: { xs: "2.188rem", md: "35px" },
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal" /* 129.948% */,
              letterSpacing: "0.4px",
              textAlign: { xs: "left", md: "left" },
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Custom Plan
          </Typography>
          <Typography
            sx={{
              width: { xs: "100%", md: "80%" },
              textAlign: { xs: "left", md: "left" },
              color: "#BBB",
              fontFamily: "Gilroy-Regular",
              fontSize: { xs: "18px", md: "18px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal" /* 143.265% */,
              letterSpacing: " 1px",
              marginLeft: "0px",
              marginRight: "auto",
              // marginTop: "23px",
              // marginTop: { xs: "50px", md: "23px" },
            }}
          >
            Contact us today to create a custom plan that meets your specific
            needs.
          </Typography>
        </Box>

        <Button
          sx={{
            marginLeft: "0px",
            marginRight: "auto",
            width: "auto",
            background: "#3FA268",
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontSize: { xs: "16px", md: "16px" },
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "28.08px",
            letterSpacing: "-0.832px",
            borderRadius: "50px",
            textAlign: "center",
            padding: "14px 44px",
            marginTop: {xs:"30px", md:"0px"},
            // textTransform: "none",
            "&:hover": {
              background: "#3FA268",
            },
          }}
          onClick={() => {
            window.open(
              "https://calendly.com/team-reslink/meeting",
              "_blank"
            );
          }}
        >
          CONTACT US
        </Button>
      </Box>
    </div>
  );
};

export default CardWithBigButtonPricing;
