import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Link,
  Button,
  Card,
} from "@mui/material";
import Sidebar from "../../components/Sidebar";
import AltNav from "../../components/AltNav";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import img1 from "../../assets/Altium.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UserCard from "./components/UserCard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import UserButton from "./components/Button";
import ArticleIcon from "@mui/icons-material/Article";

const StaffingMain = () => {
  const UserDetails = {
    user1: {
      name: "Robert Fox",
      img: "https://s3-alpha-sig.figma.com/img/f7cb/929c/6b9ca489280b3989bd93f720ae09f00c?Expires=1697414400&Signature=Ml1BIOoAlU26MnStKTzl1tVEFf-bfVCXwVE33G6GxGG8u9rCpsw3jIgZlyRNCiipFxI5CVwGV-CpXELNt~BLz9haVKfOzYkZF59G~FG4QTAhg0hPWhB6ri1ny~pOeWDXzlor2-7GGz3pTKRZNnz~JLuDL8OJvlj6M-8qqQgaWhuMUvNZqWNExJo6~-M3DDuSGqkXDSiiING0uISqbu~GAJ3c5z0il8gvPlkW76SQ~sc0-9n0blalFSbVnZFFqDnq58vl73A2o6NdeYTbeqrk9egKIhWgiZi4bPXJfeQRl4-uhoqjoYgzfYMePpMEjFvMeno0z7lZNm7V01eJIld7MQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      tag: "Good fit",
      applied: "Applied 2W ago",
      active: true,
    },
    user2: {
      name: "Arlene McCoy",
      img: "https://s3-alpha-sig.figma.com/img/d16a/f894/ee3e18965d4a4b0c77188bc5278b94cc?Expires=1697414400&Signature=WM7ahIMn9DbqJ73OORqNeRwAlAwmEOLlDdmH45cn98I0MjZDBbFNBFfIj3bLlCK8Uhhlq2B1koBVi0RfLJq~7Caz0X1oA2sGycrC6LiUSzbJNihH7ly7DqYgZwyi814XmqJ6IFsFub9FiWNL0Lwri0oQDhNrmAIwROmYwOCfCppICIX3Yp9PJRA2Yu48moCRb-C5b1~CVFat6LaADfSvQIChg7134e9ziG2kab2cwEyr2DxbvNGpZS-PFu92-vGZXDTIVzM94OViyUE8o-gFCA97yAtxe6o9adYw5GRiBsyV7FG~mJtIkUEm8qoLUzyGopRgJoh0QpWWALEd~82qJw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      tag: "Good fit",
      applied: "Applied 2W ago",
      active: false,
    },
    user3: {
      name: "Eleanor Pena",
      img: "https://s3-alpha-sig.figma.com/img/f3cf/29a8/fed39589ceb38423e65b26b8d6c93123?Expires=1697414400&Signature=qjIh7Ctb3EA8SifePer83haVsUQpxlC-TF2RZb8Ax~2IosFJ45p6rRJXu9iPudk4qt1hCcaQ8kdgqNWRFoC47AKExyO8QuT5hNe6LTYBwlixwU3vZ3GiqfMBpWhbMzUVX~KR0Ew-I8hPMmj3nicjgzHayDxSc9yoSyaiiir9b5leJD3HyxKx77rKsZEzYlh4P6xEZTwBiMzqxpBCsG~J2NmM87RUKer1UE4fJuE2m9CzX~0t8XBvP2IRJWk9~tI7isQTJs5ZufYOs2vyRop1BERuy-1djf-aaG1JNUHkrhXKDvbHElfl7tf77swVtu-WAAbaif~AdrX77iSnEKzEYg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      applied: "Applied 2W ago",
      active: false,
    },
    user4: {
      name: "Dianne Russell",
      img: "https://s3-alpha-sig.figma.com/img/5209/3bd4/d799d9dcdef8bed39a1a5c13b8b1653c?Expires=1697414400&Signature=hRohXIUpDPrw1QII1ANANVVtnQvtI3fK7zLpezD3vOq15ximaifmtH4agQrelNohmkYfOjNiJMwHa8YA1a~8DX0-fJwz39esNSY3KDHiQ1D1m8va3rJ4VJnamVb96ZOH1dwTP3ONDZ2cIqBteCPPkgz58Vxp81VY~zXR0MIk~Mf0vIYL-ycrhg-OyKe8D24FF8ErdG3nA8BxehLagV6ZPtbl9h02fT-QUi-3c~E3w-SxbPYQYAiHls4jyWYWlrhTqookky0gQUGcXq51xUplElMhM04V4OPDFa9sH2YmAJIl6W3x4rf3Y~C634sw6LtgUqLuBeUF6FYWq6GtVX7OJA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      tag: "Not a fit",
      applied: "Applied 2W ago",
      active: false,
    },
    user5: {
      name: "Wade Warren",
      img: "https://s3-alpha-sig.figma.com/img/9bfc/c3c0/f06ca2928cc089db93641820bd4a8938?Expires=1697414400&Signature=CPR-u0pW3wUdlKYW2BEPp3Yp7WWHAtuqdPDPvq0UPynYmFQq6XdbA0qupe0vkfEaSIlVZXSDbUxrX-y5nL7bKJ7z4UUVqT7o7EQ3oog62glRlbfqf7eZS~d5O8DiZnkG7o68ftfVnoHLPckYYjaoVHZE6TyemaLGNF97PYrKOT2gAbezsOmdijoFwEVoHDCigTa87rs7EZjjsbOcZZb48wPb6x2MN6N9fyzwDrgVmnTrs72hrJqXwoZ-t3SsZ50F2SMfBpCbJXm02EVegpKJMvhHLjOb6b37A~elX9cB1LzZXTwGLxoIDSTpoWSPyjqTNkYkk5RgxTDnIc6WZY6hwg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      tag: "Good fit",
      applied: "Applied 2W ago",
      active: false,
    },
    user6: {
      name: "Cody Fisher",
      img: "https://s3-alpha-sig.figma.com/img/22aa/3b45/6260f5c114826d26f0063851b8322353?Expires=1697414400&Signature=RRJv~g8V1v4DEMwDvJFI5qSvvzHhhD0ntCOs1XN3leM9VGqxf-8wP-ITTz1ApeMJfPOnEHYtMA3y97F87iQTDBiiqhJqr~cWv6WceteAsFcARZPdSbf8-bx~hpCU8feYq5ouYZKayfdEzkrySLG1zBYQPqlZKNs26bxEzkfRhaKa439ROeQzLkZ3EWGlDTpHqQ0GkmD~OZFFMMjk9HqWa6KLGdiwZDxdkdzowOHKXDLXsQZt5dncF0fjTQ6B7~vNBeeZuYpftlNNTJgHSOpcwg6CERTVWu8YzphOGtjbE694nkesjiqPiYlomP9CKtIpsIDElKZHxlqEyvu-pwZoeA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4",
      content: "Technical Researcher | 3+ years of experience",
      applied: "Applied 2W ago",
      active: false,
    },
  };

  const Buttons = {
    btn1: {
      success: true,
      text: "Good fit",
      type: "outline",
      icon: <ArrowDropDownIcon />,
    },
    btn2: {
      success: false,
      type: "outline",
      text: "Reject",
    },
    btn3: {
      success: true,
      type: "solid",
      text: "Request more interview",
      icon: <KeyboardTabIcon />,
    },
  };

  return (
    <Box
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        background:
          "radial-gradient(39.33% 60.33% at 50% 50%, #1F3B2A 20%, #272A31 100%)",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        display: "flex",
      }}
      height="100vh"
    >
      <Sidebar currentPage={"Staffing"} />
      <Box
        width="75vw"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        padding={{ xs: "0px", md: "0px 20px" }}
      >
        <AltNav btn_text={"Post A Job"} />
        <Link
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "400",
            display: "flex",
            alignItems: "center",
            opacity: "0.8",
            gap: "3px",
            marginTop: "10px",
            cursor: "pointer",
            textDecoration: "none",
          }}
          onClick={() => {
            alert("Add the route");
          }}
        >
          <ArrowBackIosIcon
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontSize: "15px",
            }}
          />
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            Post A Job
          </Typography>
        </Link>
        <Box height="34px"></Box>
        <Card
          sx={{
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.13)",
            background: "rgba(255, 255, 255, 0.04)",
            backdropFilter: "blur(10.399999618530273px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            //   height: "100%",
          }}
        >
          <Box p={0}>
            <Box padding="26px 26px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <img src={img1} alt="IMG" />
                  <Box
                    sx={{
                      marginLeft: "16px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: "500",
                        lineHeight: "normal",
                        fontFamily: "Poppins",
                        overflow: "hidden",
                        color: "var(--Primary-White, #FEFEFE)",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Technical Researcher
                    </Typography>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        color: "var(--Primary-White, #FEFEFE)",
                        textOverflow: "ellipsis",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Altium Packaging - Remote
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      background: "transparent",
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28.08px",
                      border: "2px solid",
                      borderColor: "#3FA268",
                      letterSpacing: "-0.832px",
                      borderRadius: "50px",
                      textAlign: "center",
                      padding: "10px 20px",
                      display: "flex",
                      textDecoration: "none",
                      alignItems: "center", // Center text and icon vertically
                      "&:hover": {
                        background: "#3FA268",
                      },
                    }}
                    onClick={() => {
                      window.open(
                        "https://calendly.com/team-reslink/meeting",
                        "_blank"
                      );
                    }}
                  >
                    Manage Job
                  </Button>
                  <IconButton>
                    <MoreHorizIcon
                      sx={{
                        color: "var(--Primary-White, #FEFEFE)",
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            gap: "20px",
          }}
        >
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              borderRadius: "8px",
              background: "rgba(255, 255, 255, 0.04)",
              width: "362px",
              overflowY: "scroll",
              height: "calc(100vh - 320px)",
              "&::-webkit-scrollbar": {
                width: "0px",
                scrollBehavior: "smooth",
              },
            }}
          >
            {Object.keys(UserDetails).map((key) => (
              <UserCard userData={UserDetails[key]} />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflowY: "scroll",
              height: "calc(100vh - 360px)",
              "&::-webkit-scrollbar": {
                width: "0px",
                scrollBehavior: "smooth",
              },
              padding: "20px",
              background: "rgba(255, 255, 255, 0.04)",
              borderRadius: "8px",
              flexShrink: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.13)",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontSize: "24px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Robert Fox's Application
                </Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    fontFamily: "Poppins",
                    color: "var(--Primary-White, #FEFEFE)",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    marginTop: "10px",
                  }}
                >
                  Technical Researcher | 3+ years of experience
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  fontFamily: "Poppins",
                  marginTop: "13px",
                  marginBottom: "15px",
                }}
              >
                {Object.keys(Buttons).map((key) => (
                  <UserButton btnData={Buttons[key]} />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                fontFamily: "Poppins",
                padding: "20px 0px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    fontFamily: "Poppins",
                  }}
                >
                  Experience
                </Typography>
                <Box
                  sx={{
                    marginTop: "10px",
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Poppins",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Graduate Engineering Trainee <br />
                    September 2022 - Present (1 year 1 month)
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      opacity: "0.8",
                    }}
                  >
                    Bengaluru, Karnataka, India
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Poppins",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    }}
                  >
                    Materials research intern February <br />
                    2022 - August 2022 (7 months)
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      opacity: "0.8",
                    }}
                  >
                    Bengaluru, Karnataka, India
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                >
                  Education
                </Typography>
                <Box
                  sx={{
                    marginTop: "10px",
                    color: "var(--Primary-White, #FEFEFE)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Indian Institute of Space Science and Technology
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      opacity: "0.8",
                      fontFamily: "Poppins",
                    }}
                  >
                    Master of Technology - MTech, material science · (2019 -
                    2021)
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                >
                  Work
                </Typography>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    color: "var(--Primary-White, #FEFEFE)",
                  }}
                >
                  <ArticleIcon
                    sx={{
                      fontSize: "30px",
                      color: "#3FA268",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Indian Institute of Space Science and Technology
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StaffingMain;
